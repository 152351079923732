import Header from './assets/components/Header'
import Nosotros from './assets/components/Nosotros'
import MisClientes from './assets/components/MisClientes'
import Footer from './assets/components/Footer'
import TeAyudamos from './assets/components/TeAyudamos'
import CanalesDistribucion from './assets/components/CanalesDistribucion'
import Contact from './assets/components/Contact'
// Icons
import { FaFacebook } from 'react-icons/fa'
import { BsLinkedin } from 'react-icons/bs'

const App = () => {
  return (
    <div className='flex flex-col bg-slate-50 w-full h-screen text-center'>
      <Header />
      <Nosotros />
      <MisClientes />
      <TeAyudamos />
      <CanalesDistribucion />
      <div className='w-full bg-sky-500'>
        <div className='grid justify-items-center p-2'>
          <div className='flex items-center'>
            <a href='https://www.facebook.com/dmgroup.mx'><FaFacebook className='hover:text-7xl text-6xl m-5 hover:animate-bounce hover:text-sky-900 hover:bg-white text-white bg-sky-900 focus:outline-none focus:ring-6 focus:ring-sky-300 rounded-full dark:focus:ring-sky-800' /></a>
            <a href='linkedin.com/company/dm1group'><BsLinkedin className='hover:text-7xl text-6xl m-5 hover:animate-bounce hover:text-sky-900 hover:bg-white text-white bg-sky-900 focus:outline-none focus:ring-6 focus:ring-sky-300 rounded-full dark:focus:ring-sky-800' /></a>
          </div>
        </div>
      </div>
      <Contact />
      <Footer />
    </div>
  )
}

export default App
