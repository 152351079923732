// Logos
import mexteno from '../../assets/files/logos/mexteno.jpeg'
import amormaguey from '../../assets/files/logos/amormaguey.jpeg'
import frutiqueto from '../../assets/files/logos/Frutiqueko.jpeg'
import gruppons from '../../assets/files/logos/gruppons.jpeg'
import gubernija from '../../assets/files/logos/gubernija.jpeg'
import irescaidas from '../../assets/files/logos/irescaidas.jpeg'
import naturalGoodShape from '../../assets/files/logos/NaturalGoodShape.jpeg'
import organic from '../../assets/files/logos/organic.jpeg'
import oxefruit from '../../assets/files/logos/oxefruit.jpeg'
import sanavi from '../../assets/files/logos/sanavi.jpeg'
import sangritamia from '../../assets/files/logos/sangritamia.jpeg'
import shinobi from '../../assets/files/logos/shinobi.jpeg'
import sinpasado from '../../assets/files/logos/sinpasado.jpeg'
import wembley from '../../assets/files/logos/wembley.jpeg'
import yoor from '../../assets/files/logos/Yoor.jpeg'
import yumi from '../../assets/files/logos/yumi.jpeg'

const MisClientes = () => {
  const ImageLogo = ({ src }) => (
    <div className='grid justify-items-center mt-2'>
      <img src={src} alt='logo-business' className='w-2/3 hover:animate-bounce hover:scale-125 border-b-2 hover:border-b-sky-500 border-b-gray-300' />
    </div>
  )

  return (
    <>
      <hr className='text-gray-900 h-4 mt-10' />
      <div className='mx-auto container text-center py-10'>
        <p className='text-2xl sm:text-4xl font-bold text-sky-400'>
          Nuestro equipo
        </p>
      </div>
      <div className='grid grid-cols-2 sm:md:grid-cols-4 gap-4 mx-auto container'>
        <ImageLogo src={mexteno} />
        <ImageLogo src={frutiqueto} />
        <ImageLogo src={oxefruit} />
        <ImageLogo src={naturalGoodShape} />
        <ImageLogo src={yumi} />
        <ImageLogo src={sanavi} />
        <ImageLogo src={sinpasado} />
        <ImageLogo src={gruppons} />
        <ImageLogo src={wembley} />
        <ImageLogo src={sangritamia} />
        <ImageLogo src={amormaguey} />
        <ImageLogo src={shinobi} />
        <ImageLogo src={organic} />
        <ImageLogo src={yoor} />
        <ImageLogo src={irescaidas} />
        <ImageLogo src={gubernija} />
      </div>
    </>
  )
}

export default MisClientes
