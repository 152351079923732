import solucionesCopy from '../files/SolucionesCopy.jpeg'

const TeAyudamos = () => {
  return (
    <section className='pt-10 pb-6 '>
      <div className='grid grid-cols-1 gap-12 text-center'>
        <div className='w-full'>
          <div className=' h-96 w-full' style={{ backgroundImage: `url(${solucionesCopy})` }}> </div>
          <div className='max-w-5xl mx-auto px-6 sm:px-6 lg:px-8 mb-12'>
            <div className='bg-white w-full shadow rounded  -mt-72'>
              <div className='grid sm:md:grid-cols-3 grid-cols-1 gap-0'>
                <div className='col-span-1 bg-sky-500 p-10 grid justify-items-center'>
                  <h2 className='text-2xl font-light text-white pt-5 flex items-center'>
                    <p>Te ayudamos a <span className='font-bold'>vender tus productos en México</span></p>
                  </h2>
                </div>
                <div className='col-span-2 bg-slate-100 px-10 pt-4 grid justify-items-center'>
                  <h2 className='sm:md:text-xl text-md text-justify font-light text-slate-900 sm:md:py-10 py-5 '>
                    DM Group fue creado para asistir compañías extranjeras y nacionales; en respuesta a la necesidad de generar mayores ventas, participación de mercado y relaciones con distribuidores .Por medio de representar tu compañía y desarrollarla en el mercado Mexicano; por medio de distribuidores, clubes de precio, cadenas de autoservicio nacionales y regionales hard discount, tiendas de conveniencia, mayoristas, restaurantes, hoteles y cadenas de comida rápida de México.)
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TeAyudamos
