import { useState } from 'react'
import '../../App.css'
import bgVideo from '../files/Comp-1.mp4'
import logowb from '../files/logowb.svg'

const Header = () => {
  const [anuncio, setAnuncio] = useState('Clubes de precio, Autoservicios, Tiendas de Conveniencia, Tradicional, Ecommerce y Food Service')

  const anuncios = [
    'Clubes de precio, Autoservicios, Tiendas de Conveniencia, Tradicional, Ecommerce y Food Service',
    'Ecommerce',
    'Tradicional',
    'Tiendas de Conveniencia',
    'Autoservicios',
    'Clubes de precio',
    'Food Service'
  ]

  setTimeout(() => {
    setAnuncio(getRandom(anuncios))
  }, 10000)

  const getRandom = (arr) => {
    return arr[Math.floor(Math.random() * arr.length)]
  }

  return (
    <article className='bg-transparent'>
      <div className='video-wrapper h-2/3'>
        <div className='video-title w-full bg-transparent text-white'>
          <div className='text-center xl:text-7xl lg:text-5xl md:text-4xl sm:text-3xl'>
            <div className='sm:md:pt-20 pt-0 grid sm:md:justify-items-center justify-items-start'>
              <img src={logowb} alt='Logo' className='sm:md:h-28 h-2/3 sm:md:w-full w-1/2 py-2' />
            </div>
            <div className='sm:md:my-5 lg:xl:my-10'>
              <p className='font-light'>¿Te interesa <span className='font-bold'>vender tus </span></p>
              <p><span className='pl-10 sm:md:pl-20  font-bold'>productos</span> en México?</p>
              <p className='sm:text-sm md:text-md xl:text-4xl lg:text-2xl sm:pt-1 sm:px-1 md:pt-2 md:px-1 lg:pt-5 lg:px-5 xl:pt-7 xl:px-7 text-blue-500'>{anuncio}</p>
            </div>
          </div>
        </div>
        <video autoPlay loop muted className='w-full'>
          <source src={bgVideo} type='video/mp4' />
        </video>
      </div>
    </article>
  )
}

export default Header
