import nosotros from '../files/nosotros.jpeg'

const Nosotros = () => {
  return (
    <div className='mx-auto container'>
      <div className='grid sm:md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-12'>
        <div>
          <img src={nosotros} alt='Nosotros' className='h-full w-full sm:md:pt-2 pt-0' />
        </div>
        <div className='text-justify p-5 xl:col-span-2'>
          <p className='text-2xl sm:text-4xl font-medium text-sky-500 pt-3'>
            Nosotros
          </p>
          <p className='text-sm sm:text-lg font-light py-2 text-justify text-gray-700 '>
            Con más de 15 años en la industria alimenticia y comprendiendo las necesidades de empresas que quieren introducirse en el mercado Mexicano, decidimos crear DM Group; para desarrollar el negocio de empresas nacionales y/o extranjeras, introduciendo sus productos y marcas al mercado Mexicano, en los distintos canales de venta (Distribuidores, clubes de precio, cadenas de autoservicio nacionales y regionales, hard discount, tiendas de conveniencia, mayoristas, restaurantes, hoteles y cadenas de comida rápida.)
          </p>
          <p className='text-2xl sm:text-4xl font-medium text-sky-500 pt-3'>
            Nuestro equipo
          </p>
          <p className='text-sm sm:text-lg font-light px-4 text-justify text-gray-700'>
            <li className='pt-2'>Cuenta con un equipo de 4 KAMS y 2 CPFR ubicados estratégicamente en el territorio nacional. </li>
            <li className='pt-2'>Cuenta con una plataforma de lectura de sell out y app de control de la fuerza de promotoria. </li>
            <li className='pt-2'>Cuenta con CRM para el control de la fuerza de ventas para el canal tradicional. </li>
            <li className='pt-2'>Partnership con agencias expertas en MKT, TMK y Promotoria ( Con mas de 15 años de experiencia ). </li>
            <li className='pt-2'>Partnership con los distribuidores mas importantes por región. </li>
            <li className='pt-2'>Partnership con porteadores ubicados estratégicamente en el territorio nacional. </li>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Nosotros
