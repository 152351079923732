import bgcanales from '../../assets/files/bg_canales.jpeg'

const CanalesDistribucion = () => {
  const ElementIn = ({ title, text }) => (
    <div className='flex flex-col justify-left items-left pt-2'>
      <p className='text-xl font-bold capitalize text-sky-500'>
        {title}
      </p>
      <p className='text-md font-medium text-white pt-2'>{text}</p>
    </div>
  )
  return (
    <div
      className='w-full bg-cover bg-center grid justify-items-center'
      style={{ backgroundImage: `url(${bgcanales})` }}
    >
      <div className='mx-auto container backdrop-blur-xl text-left p-5'>
        <p className='text-4xl text-white'>Canales de distribucion</p>
        <div className='grid sm:md:grid-cols-3 lg:xl:grid-cols-5 pt-5 rounded p-2'>
          <div className='w-full grid grid-cols-2 lg:xl:col-span-3 md:col-span-2 sm:col-span-3'>
            <ElementIn
              title='Clubes de precio, Autoservicios y Hard Discount'
              text='Hay más de 25 cadenas de autoservicio con más de 7,000 puntos de venta. Este es el mercado mas importante en la industria alimentaría, pero al mismo tiempo es el mercado mas sensible a los precios y calidad.'
            />
            <ElementIn
              title='Tiendas de conveniencia'
              text='Hoy día existen mas de 6 cadenas con más de 30,000 puntos de venta. Este es el mercado mas importante en la industria alimentaría, pero al mismo tiempo es el mercado mas sensible a los precios y calidad. '
            />
            <ElementIn
              title='Ecommerce'
              text='Hoy día existen mas de 5 marketplace lideres en Mexico. Este es uno de los canales con mayor crecimiento y la próxima revolución de las compras. '
            />
            <ElementIn
              title='Food Service'
              text='Este es uno de los segmentos más importantes en la industria, tenemos una enorme cantidad y variedad de cadenas de comida rápida tanto mexicanas como americanas que operan en México. '
            />
            <div className='flex flex-col justify-left items-left pt-2 col-span-2'>
              <p className='text-2xl font-bold capitalize text-sky-500'>
                Hoteles
              </p>
              <p className='text-md font-medium text-white pt-2'>Este mercado es uno de los mas importantes ya que es la segunda industria que deja mayor cantidad de divisas en nuestro país. Este mercado cuenta con mas de 400,000 cuartos y se encuentra concentrado en el sur este de la república mexicana y es un mercado que acepta muy bien los productos importados.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CanalesDistribucion
