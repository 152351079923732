import emailjs from 'emailjs-com'

const FromContact = () => {
  const sendEmail = (e) => {
    console.log('activo')
    e.preventDefault()

    emailjs.sendForm(
      'service_2oiyy2o',
      'template_rfeym94',
      e.target,
      'xMrfr23hZeVa59UfT'
    )
      .then(function (response) {
        console.log('SUCCESS!', response.status, response.text)
        alert('Correo enviado !')
      }, function (error) {
        console.log(error)
        alert('Revise bien la informacion !.')
      })
  }
  return (
    <form onSubmit={sendEmail}>
      <p className='text-sky-900 sm:md:text-2xl text-sm font-medium sm:md:mx-10 sm:md:px-10 mx-2 px-2 mb-6 text-left'>Envíanos tu solicitud</p>
      <div className='mb-6'>
        <label className='block mb-2 text-sm font-medium text-gray-600'>Tu nombre <span className='text-red-500'>*</span></label>
        <input name='name' type='text' className='bg-sky-50 border border-sky-300 text-gray-900 text-sm rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5 dark:border-sky-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-sky-500 dark:focus:border-sky-500' />
      </div>
      <div className='mb-6'>
        <label className='block mb-2 text-sm font-medium text-gray-600'>Tu correo <span className='text-red-500'>*</span></label>
        <input name='email' type='email' className='bg-sky-50 border border-sky-300 text-gray-900 text-sm rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5 dark:border-sky-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-sky-500 dark:focus:border-sky-500' placeholder='ventas@dmgroup.mx' />
      </div>
      <div className='mb-6'>
        <label className='block mb-2 text-sm font-medium text-gray-600'>Tu telefono <span className='text-red-500'>*</span></label>
        <input name='tel' type='tel' className='bg-sky-50 border border-sky-300 text-gray-900 text-sm rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5 dark:border-sky-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-sky-500 dark:focus:border-sky-500' placeholder='55-1392-0600' />
      </div>
      <div className='mb-6'>
        <label className='block mb-2 text-sm font-medium text-gray-600'>Mensaje</label>
        <textarea name='message' rows='4' className='bg-sky-50 border border-sky-300 text-gray-900 text-sm rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5 dark:border-sky-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-sky-500 dark:focus:border-sky-500'> </textarea>
      </div>
      <div className='mb-6 grid justify-items-center'>
        <button className='bg-sky-500 hover:bg-sky-700 px-12 py-2 text-lg font-medium rounded text-white' type='submit'>Enviar</button>
      </div>
    </form>
  )
}

export default FromContact
